import { browser, dev } from '$app/environment';
import { page as pageStore } from '$app/stores';
import * as Sentry from '@sentry/svelte';
import type { NavigationEvent, Page } from '@sveltejs/kit';
import { get } from 'svelte/store';
import { auth } from '../index.js';

export const CLIENT_TUNNEL = 'https://app.helicalco.com/api/sentry/tunnel';

export function init(dsn: string) {
	if (dev) return;
	Sentry.init({
		dsn: dsn,
		tunnel: CLIENT_TUNNEL,

		// Set Content-Type for the requests, otherwise we hit sveltekits CSRF protection.
		transportOptions: { headers: { 'Content-Type': 'application/json' } },

		integrations: [Sentry.browserTracingIntegration({})],

		enableTracing: false
	});
}

function capture_event(ev: Sentry.Event) {
	if (dev || !browser) return;
	Sentry.captureEvent(ev);
}

export function captureClientError(err: unknown, event: NavigationEvent, ev: Sentry.Event) {
	ev.extra ??= {};
	ev.extra.route = event.route;
	ev.request ??= {};
	ev.request.url = event.url?.toString();

	add_common_event_meta(err, ev);

	capture_event(ev);
}

export function sendMessage(message: string, err?: unknown) {
	const page = get(pageStore) || ({} as Page);

	const ev: Sentry.Event = {
		message,
		extra: {
			route: page.route?.id,
			url: page.url?.toString()
		}
	};
	add_common_event_meta(err, ev);

	capture_event(ev);
}

function add_common_event_meta(err: unknown, ev: Sentry.Event) {
	if (err instanceof Error) {
		ev.exception = {
			values: [Sentry.exceptionFromError(Sentry.defaultStackParser, err)]
		};
	} else if (err) {
		ev.extra.error = err;
	}

	let userID: string;
	if (auth) {
		userID = get(auth)?.jwt?.userID;
		ev.user = { id: userID };
	}
	return ev;
}
