import { dev } from '$app/environment';
import { page } from '$app/stores';
import { PUBLIC_APP_VERSION } from '$env/static/public';
import { SENTRY_DSN } from '$lib/env.js';
import type { User } from '$lib/server/db/types.js';
import { errorStr } from '@helicalco/components';
import { captureClientError, init } from '@helicalco/components/sentry/client';
import type { HandleClientError } from '@sveltejs/kit';
import { get } from 'svelte/store';

init(SENTRY_DSN);

export const handleError: HandleClientError = async ({ error, event, message }) => {
	if (dev) {
		console.error('CLIENT ERROR:\n', error);
	} else {
		try {
			let userID: string;
			if (page) {
				const data = get(page);
				userID = (data?.data?.pscaleuser as User)?.id;
			}
			captureClientError(error, event, {
				message,
				release: PUBLIC_APP_VERSION,
				user: { id: userID }
			});
		} catch (e) {
			console.error('sending error to sentry:', e);
		}
	}

	if (event.route.id == null) {
		return {
			code: 404,
			message: 'Page not found'
		};
	}

	return {
		code: 500,
		message: await errorStr(error)
	};
};
